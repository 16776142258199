/***** Datas *****/

/* Infos content trads */
var wheelInfos = [
    {
        id: 'aries',
        idElement: 'fire',
        content: {
            name: Translator.translate('wheel_infos_aries'),
            signe: Translator.translate('wheel_infos_aries'),
            element: Translator.translate('wheel_infos_fire_element'),
            dates: Translator.translate('wheel_infos_aries_dates')
        }
    },
    {
        id: 'taurus',
        idElement: 'earth',
        content: {
            name: Translator.translate('wheel_infos_taurus'),
            signe: Translator.translate('wheel_infos_taurus'),
            element: Translator.translate('wheel_infos_earth_element'),
            dates: Translator.translate('wheel_infos_taurus_dates')
        }
    },
    {
        id: 'gemini',
        idElement: 'air',
        content: {
            name: Translator.translate('wheel_infos_gemini'),
            signe: Translator.translate('wheel_infos_gemini'),
            element: Translator.translate('wheel_infos_air_element'),
            dates: Translator.translate('wheel_infos_gemini_dates')
        }
    },
    {
        id: 'cancer',
        idElement: 'water',
        content: {
            name: Translator.translate('wheel_infos_cancer'),
            signe: Translator.translate('wheel_infos_cancer'),
            element: Translator.translate('wheel_infos_water_element'),
            dates: Translator.translate('wheel_infos_cancer_dates')
        }
    },
    {
        id: 'leo',
        idElement: 'fire',
        content: {
            name: Translator.translate('wheel_infos_leo'),
            signe: Translator.translate('wheel_infos_leo'),
            element: Translator.translate('wheel_infos_fire_element'),
            dates: Translator.translate('wheel_infos_leo_dates')
        }
    },
    {
        id: 'virgo',
        idElement: 'earth',
        content: {
            name: Translator.translate('wheel_infos_virgo'),
            signe: Translator.translate('wheel_infos_virgo'),
            element: Translator.translate('wheel_infos_earth_element'),
            dates: Translator.translate('wheel_infos_virgo_dates')
        }
    },
    {
        id: 'libra',
        idElement: 'air',
        content: {
            name: Translator.translate('wheel_infos_libra'),
            signe: Translator.translate('wheel_infos_libra'),
            element: Translator.translate('wheel_infos_air_element'),
            dates: Translator.translate('wheel_infos_libra_dates')
        }
    },
    {
        id: 'scorpio',
        idElement: 'water',
        content: {
            name: Translator.translate('wheel_infos_scorpio'),
            signe: Translator.translate('wheel_infos_scorpio'),
            element: Translator.translate('wheel_infos_water_element'),
            dates: Translator.translate('wheel_infos_scorpio_dates')
        }
    },
    {
        id: 'sagittarius',
        idElement: 'fire',
        content: {
            name: Translator.translate('wheel_infos_sagittarius'),
            signe: Translator.translate('wheel_infos_sagittarius'),
            element: Translator.translate('wheel_infos_fire_element'),
            dates: Translator.translate('wheel_infos_sagittarius_dates')
        }
    },
    {
        id: 'capricorn',
        idElement: 'earth',
        content: {
            name: Translator.translate('wheel_infos_capricorn'),
            signe: Translator.translate('wheel_infos_capricorn'),
            element: Translator.translate('wheel_infos_earth_element'),
            dates: Translator.translate('wheel_infos_capricorn_dates')
        }
    },
    {
        id: 'aquarius',
        idElement: 'air',
        content: {
            name: Translator.translate('wheel_infos_aquarius'),
            signe: Translator.translate('wheel_infos_aquarius'),
            element: Translator.translate('wheel_infos_air_element'),
            dates: Translator.translate('wheel_infos_aquarius_dates')
        }
    },
    {
        id: 'pisces',
        idElement: 'water',
        content: {
            name: Translator.translate('wheel_infos_pisces'),
            signe: Translator.translate('wheel_infos_pisces'),
            element: Translator.translate('wheel_infos_water_element'),
            dates: Translator.translate('wheel_infos_pisces_dates')
        }
    }
];

/* Aside infos content trads */
var asideInfosContent = {
    content: {
        solar_signe: Translator.translate('wheel_infos_aside_content_solar_sign'),
        your_element: Translator.translate('wheel_infos_aside_content_your_element'),
        discover_candle: Translator.translate('wheel_infos_aside_content_discover_candle')
    }
}

/***** Functions *****/

/* Add or remove class function */
function toggleClass(className, array, add, remove) {

    for (var i = 0; i < array.length; i++) {
        var element = array[i];

        if (add && !element.classList.contains(className)) {
            element.classList.add(className);
        }

        if (remove && element.classList.contains(className)) {
            element.classList.remove(className);
        }
    }
}

/**
 * Initializes the wheel component by setting the active cell based on the current date,
 * updating the content and event listeners for the wheel cells.
 *
 * - Sets the default language to 'fr' if `langSite` is undefined.
 * - Determines the current date and sets the initial active cell based on the astrological sign.
 * - Updates the content of various elements based on the initial active cell.
 * - Adds click event listeners to each wheel cell to update the content and active cell on click.
 *
 * @global {string} langSite - The language of the site.
 * @global {string} timestampSign - The timestamp used to determine the current date.
 * @global {Array} wheelInfos - Array containing information about each astrological sign.
 * @global {Object} asideInfosContent - Object containing additional information for the aside content.
 * @global {string} path_relative_root - The root path used to create links.
 * @global {Object} Translator - Object used for translating text.
 */
function setWheel() {

    if (typeof langSite === 'undefined') {
        langSite = 'fr';
    }
    /* Wheel cells */
    var signeCellList = document.querySelectorAll('.signe_cell');

    // Retrieving the date
    if (typeof timestampSign !== 'undefined' && timestampSign.length > 0) {
        var time = new Date(timestampSign);
    } else {
        var time = new Date();
    }
    // Retrieving the day
    var date = time.getDate();
    // Retrieving the month, the +1 is to get the correct month
    var month = time.getMonth() + 1
    // To properly set the center circle
    var astroRank = 0;

    if (month == 1 && date >= 21 || month == 2 && date <= 18) {
        var initialActiveCell = document.querySelector('#cell_aquarius');
        astroRank = 10;
    } else if (month == 2 && date >= 19 || month == 3 && date <= 20) {
        var initialActiveCell = document.querySelector('#cell_pisces');
        astroRank = 11;
    } else if (month == 3 && date >= 21 || month == 4 && date <= 20) {
        var initialActiveCell = document.querySelector('#cell_aries');
        astroRank = 0;
    } else if (month == 4 && date >= 21 || month == 5 && date <= 20) {
        var initialActiveCell = document.querySelector('#cell_taurus');
        astroRank = 1;
    } else if (month == 5 && date >= 21 || month == 6 && date <= 20) {
        var initialActiveCell = document.querySelector('#cell_gemini');
        astroRank = 2;
    } else if (month == 6 && date >= 21 || month == 7 && date <= 22) {
        var initialActiveCell = document.querySelector('#cell_cancer');
        astroRank = 3;
    } else if (month == 7 && date >= 23 || month == 8 && date <= 22) {
        var initialActiveCell = document.querySelector('#cell_leo');
        astroRank = 4;
    } else if (month == 8 && date >= 23 || month == 9 && date <= 22) {
        var initialActiveCell = document.querySelector('#cell_virgo');
        astroRank = 5;
    } else if (month == 9 && date >= 23 || month == 10 && date <= 23) {
        var initialActiveCell = document.querySelector('#cell_libra');
        astroRank = 6;
    } else if (month == 10 && date >= 24 || month == 11 && date <= 22) {
        var initialActiveCell = document.querySelector('#cell_scorpio');
        astroRank = 7;
    } else if (month == 11 && date >= 23 || month == 12 && date <= 21) {
        var initialActiveCell = document.querySelector('#cell_sagittarius');
        astroRank = 8;
    } else if (month == 12 && date >= 22 || month == 1 && date <= 20) {
        var initialActiveCell = document.querySelector('#cell_capricorn');
        astroRank = 9;
    }

    /* Infos content */
    var signeTitle = document.querySelector('.infosContent--signe .infosContent__title');
    var elementTitle = document.querySelector('.infosContent--element .infosContent__title');
    var centerSigne = document.querySelector('.wheelCenterInfos__signe');
    var signeDates = document.querySelector('.wheelCenterInfos__dates');
    var wheelCenter = document.querySelector('.wheelCenterInfos');
    var elementPicto = document.querySelector('.wheelAsideInfos');

    /* Aside infos content */
    var signePreTitle = document.querySelector('.infosContent--signe .infosContent__pretitle');
    var elementPreTitle = document.querySelector('.infosContent--element .infosContent__pretitle');
    var wheelCta = document.querySelector('#wheelCta');

    /* Elements with visibility transition */
    var arrayElementsToggleVisibility = [signeTitle, elementTitle, wheelCenter, elementPicto];

    /* Set initial active cell */
    initialActiveCell.classList.add('active');

    var object = wheelInfos[astroRank].content.name;

    /* Set initial CTA link if path_relative_root is defined */
    if (typeof path_relative_root !== "undefined") {
        wheelCta.href = setWheelCta(object);
    }

    /* Set initial default content */
    var initialWheelInfosIndex = wheelInfos[astroRank];
    signeTitle.innerHTML = initialWheelInfosIndex.content.signe;
    elementTitle.innerHTML = initialWheelInfosIndex.content.element;
    var signeDatesData = initialWheelInfosIndex.content.dates.split('-');
    centerSigne.innerHTML = initialWheelInfosIndex.content.signe;
    signeDates.innerHTML = '<span>' + signeDatesData[0] + '</span><span>' + signeDatesData[1] + '</span>';
    wheelCenter.className = 'wheelCenterInfos ' + initialWheelInfosIndex.idElement;

    /* Set initial aside content */
    var asideInfosContentLang = asideInfosContent.content;
    signePreTitle.innerHTML = asideInfosContentLang.solar_signe;
    elementPreTitle.innerHTML = asideInfosContentLang.your_element;
    wheelCta.innerHTML = asideInfosContentLang.discover_candle;

    /* Add class for transition */
    toggleClass('is-visible', arrayElementsToggleVisibility, true, false);

    for (var i = 0; i < signeCellList.length; i++) {
        var signeCell = signeCellList[i];

        signeCell.addEventListener('click', function () {
            var dataValue = this.dataset.value;

            for (var j = 0; j < signeCellList.length; j++) {
                signeCellList[j].classList.remove('active');
            }

            for (var k = 0; k < wheelInfos.length; k++) {

                if (wheelInfos[k].id === dataValue) {
                    var thisSigneInfos = wheelInfos[k];
                    var thisSigneInfosLang = thisSigneInfos.content;
                    var object = thisSigneInfos.content.name;

                    /* Set cta link sur la home */
                    // Page de maintenance + surcharge path_relative_root n'est pas défini et il n'y a pas de CTA
                    if (typeof path_relative_root !== "undefined") {
                        wheelCta.href = setWheelCta(object);
                    }

                    /* Remove class for transition */
                    toggleClass('is-visible', arrayElementsToggleVisibility, false, true);

                    setTimeout(() => {
                        /* Replace content */
                        signeTitle.innerHTML = thisSigneInfosLang.signe;
                        elementTitle.innerHTML = thisSigneInfosLang.element;
                        var signeDatesData = thisSigneInfosLang.dates.split('-');
                        centerSigne.innerHTML = thisSigneInfosLang.signe;
                        signeDates.innerHTML = '<span>' + signeDatesData[0] + '</span><span>' + signeDatesData[1] + '</span>';
                        wheelCenter.className = 'wheelCenterInfos ' + thisSigneInfos.idElement;
                        elementPicto.className = 'wheelAsideInfos bgSprite bfr ' + thisSigneInfos.idElement + '_filled';

                        /* Add class for transition */
                        toggleClass('is-visible', arrayElementsToggleVisibility, true, false);
                    }, 300);
                }
            }

            this.classList.add('active');
        })
    }
}

/**
 * Generates a CTA (Call To Action) link for a given object.
 *
 * @function setWheelCta
 *
 * @param {string} object - The object for which the CTA link is generated.
 *
 * @returns {string} The generated CTA link.
 *
 * @author Stefane DR <sdr@wshop.com>
 */
function setWheelCta(object) {
    if (typeof path_relative_root !== "undefined") {
        return path_relative_root + create_link({
            'routeId': 'objects_category_super',
            'category_super': Translator.translate('wheel_infos_eshop'),
            'category': Translator.translate('wheel_infos_candles'),
            'subcategory': Translator.translate('wheel_infos_signs'),
            'object': object.toLowerCase()
        });
    }

    return '#';
}

/* Wheel display function call */
if (document.querySelector('#wheelSvg')) {
    setWheel();
}
